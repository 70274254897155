@import '../../../assets/styles/index';

.advanced-filters-modal {
  top: 24px;
  .ant-modal-header {
    padding-bottom: 16px;
    border-bottom: 1px solid $strokeLightGray;
  }
  .ant-collapse {
    .ant-collapse-header, .ant-collapse-content-box {
      align-items: center;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .ant-collapse-header-text {
        @include h4;
        color: $heading;
        flex: none !important;
        margin-inline-end: unset !important;
      }
      .ant-collapse-expand-icon {
        order: 1;
        margin-left: 8px;
        path {
          color: #959595;
        }
      }
    }
    .ant-collapse-content-box {
      border-bottom: 1px solid $strokeLightGray;
    }
  }

  .website-additional {
    margin-top: 27px;
    display: flex;
    align-items: center;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.advanced-filters-modal .ant-modal-footer {
  position: sticky;
  bottom: 0;
  background: rgba(255, 255, 255, 0.609);
  padding: 15px;
  z-index: 10;
}
