@import '../../../../assets/styles/index';

.card {
  padding: 20px;
  width: 100%;
  border: 1px solid $strokeLightGray;
  box-shadow: 0 0 20px rgba(127, 127, 127, 0.15);
  border-radius: 28px;
  transition: all 0.3s;
  &.selected {
    border: 1px solid $strokeBlue;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .card-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 25px;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .left-container {
      .icons {
        svg {
          height: 20px;
          width: 20px;
        }
      }
      .avatar {
        border-radius: 10px;
      }
    }

    .right-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media (max-width: 900px) {
        margin-left: 0;
      }

      .title-container {
        display: flex;

        @media (max-width: 900px) {
          flex-direction: column;
          font-size: 14px;
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media (max-width: 900px) {
        align-items: flex-start;
      }
    }

  }

  .contact-data-container {
    @include main;
    color: $main;

    @media (max-width: 900px) {
      font-size: 20px;
    }
  }
}
