@import './colors';
@import './fonts';

//sizes

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

//text

.main-text {
  @include main;
  color: $main;
}

.hintText {
  @include tag;
  color: $accentBlue;
}

.h1 {
  @include h1;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h2 {
  @include h2;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h3 {
  @include h3;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.h4 {
  @include h4;
  color: $heading;
  margin-top: 0;
  margin-bottom: 0;
}

.tag {
  @include tag;
  color: $gray;
}

.vertical-divider {
  color: $strokeGray;
  @include main;
}

.btn {
  @include button;
  color: $accentBlue;
}

.bold {
  font-weight: 500;
}

.lowercase {
  text-transform: lowercase;
}

//aligns

.alignCenter  {
  display: flex;
  align-items: center;
}

//spaces

.full-width-space {
  width: 100%;
  .ant-space-item:first-child {
    width: 100%;
  }
}

.align-items-space {
  .ant-space-item:first-child {
    display: flex;
    align-items: center;
  }
}

.noBottomMargin {
  margin-bottom: 0;
}

.mt-8 {
  margin-top: 8px !important;
}


.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

// Text overflow and wrapping

.textWrap {
  white-space: wrap;
}

//ant-btn

.ant-btn {
  @include button;
  padding: 18px 15px;

  &-primary {
    background-color: $accentBlue;
    color: $white;
  }

  &-default {
    border-color: $strokeGray;
    color: $heading;

    &:not(:disabled):hover,
    &:not(:disabled):active {
      border-color: $accentBlue;
      color: $accentBlue !important;
    }
  }

  &-link {
    color: $accentBlue;
  }
}

//ant-checkbox

.ant-checkbox:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked .ant-checkbox-inner {
  border-color: $accentBlue;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $accentBlue;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: $accentBlue !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) .ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: $accentBlue !important;
  border-color: $accentBlue !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $accentBlue !important;
  border-color: $accentBlue !important;
}

//ant-select

.ant-select,
.ant-select-multiple {
  @include main;

  &:not(.ant-select-status-error) {
    &:hover .ant-select-selector,
    &.ant-select-focused .ant-select-selector,
    &.ant-select:focus .ant-select-selector {
      border-color: $accentBlue !important;
    }
  }
}

//ant-radio

.ant-radio-wrapper {
  .ant-radio-checked .ant-radio-inner {
    background-color: $accentBlue !important;
    border-color: $accentBlue !important;
  }
  &:hover .ant-radio-inner {
    border-color: $accentBlue !important;
  }
}

//ant-slider

.ant-slider {
  .ant-slider-track {
    background-color: $accentBlue !important;
  }
  .ant-slider-dot-active {
    border-color: $accentBlue !important;
  }
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px $accentBlue;
  }
  .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 2.5px $accentBlue;
  }
}

  //ant-input

  .ant-input-number:not(.ant-input-status-error):hover,
  .ant-input-outlined:not(.ant-input-status-error):focus,
  .ant-input-outlined:not(.ant-input-status-error):focus-within,
  .ant-input-outlined:not(.ant-input-status-error):hover,
  .ant-input-number-focused:not(.ant-input-status-error),
  .ant-input-number:not(.ant-input-status-error):focus {
    border-color: $accentBlue !important;
  }

  //swiper

  .swiper-slide {
    cursor: grab;
  }

  .swiper-slide:active {
    cursor: grabbing;
  }

  //ant-modal

  //.ant-modal .ant-modal-footer {
  //  margin-top: 24px;
  //}

  .ant-modal-footer {
    display: flex;
    gap: 8px;
    .ant-btn {
      flex: 1;
    }
  }

  //ant-form

  .ant-form-item, .ant-form-item .ant-form-item-label >label, .ant-checkbox-wrapper, .ant-input-number {
    @include main;
    color: $main !important;
  }

  .ant-form-item-required {
    width: 100%;
  }

  //and-badge

  .premium-badge{
    //width: 100% !important;
    display: flex;
    align-items: center;
  }

  //a

  a {
    color: $accentBlue;
  }

  //ant-switch

  .ant-switch.ant-switch-checked {
    background: $accentBlue !important;
  }

  //ant-tabs

  .ant-tabs-tab-btn {
    @include h4;
  }

  //upload

  .base-service-upload {
    display: flex;
    .ant-btn {
      padding: 0 !important;
    }
  }

  .categories-select {
    min-width: 760px;
    .ant-select-selection-item {
      align-items: baseline;
    }
  }

  .error-message {
    color: $red;
    margin: 4px 0 8px 0;
  }


  //ant-table

  .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
    background: none !important;
  }

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}