// MAIN COLORS
$white: #FFFFFF;
$yellow: #FFD700;
$red: #FF4D4F;
$green: #34A33B;
$lightGreen: #DDF7DE;
$accentBlue: #2A66CF;

// TEXT COLORS
$main: #494A4C;
$heading: #1E2231;
$gray: #959595;

// STROKE COLORS
$strokeBlue: #AAC3EE;
$strokeGray: #D9D9D9;
$strokeLightGray: #F0F0F4;

// MENU COLORS
$mutedWhite: #f7f9f9;
$deepPurple: #342C5A;
$darkBlue: #15202B;
$steelBlue: #384757;
$softGrayWhite: rgba(239, 243, 244, 0.1);
$transparentBlack: rgba(0, 0, 0, 0.06);
