body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sr-only {
  display: none;
}

input.copieble {
  width: 560px;
  background-color: #f5fcff;
  border: 1px;
  text-align: center;
  cursor: pointer;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

//.ant-modal-title {
//  text-align: center;
//  font-size: 22px !important;
//}

.top-search-bar > .ant-col > label {
  font-size: 20px !important;
}

.profile-card {
  background-color: #FAFAFA !important;
}

.advanced-form .ant-form-item-label {
  overflow: visible !important;
  text-align: left !important;
}

.advanced-form .ant-form-item-row {
  align-items: center;
}

.anticon-close {
  font-size: 14px !important;
}

.ant-card-body {
  background-color: #fafafa;
}

.card-white-bg .ant-card-body {
  background-color: white;
}

.ant-card-body .ant-card-meta-title {
  font-size: 24px;
}

.ant-card-body .ant-statistic-title {
  font-size: 18px;
  color: #000000;
  text-decoration: underline;
}

.desktop {
  display: flex !important;
}

.mobile {
  display: none !important;
}

@media (max-width: 900px) {
  .mobile-hidden {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .desktop {
    display: none !important;
  }
}

.search-form {
  display: flex !important;
  flex-direction: column !important;
}

@media (max-width: 1155px) {
  .top-search-bar > .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.city-or-town-input {
  margin: 0 !important;
}

.slick-prev, .slick-next {
  color: black !important;
  z-index: 1;
}

.slick-prev:hover, .slick-next:hover {
  color: #1890ff !important;
}

.slick-dots li.slick-active button {
  background-color: black !important;
}

.slick-dots li button {
  background-color: grey !important;
}

p.help-p1 {
  font-size: 12px;
  text-align: left;
}

p.help-p2 {
  font-size: 14px;
  text-align: left;
}

.divider {
  height: 300px;
  width: 1px;
  background-color: rgb(195, 195, 195);
}

.slick-slider {
  max-width: 275px !important;
}

.checkout_modal {
  overflow: hidden !important;
}

.checkout_modal .ant-modal-content {
  overflow: scroll !important;
  height: 100% !important;
}

.testimonial_section {
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: auto !important;
}

@media (max-width: 750px) {
  .top-search-bar, .top-search-bar .ant-form-item-row, .top-search-bar .ant-form-item-control {
    width: 100% !important;
  }
  .top-search-bar .ant-select {
    width: 100% !important;
    max-width: 90% !important;
  }

  .top-search-bar .ant-form-item {
    margin-bottom: 2px !important;
  }

  .top-search-bar .ant-form-item {
    width: 100% !important;
  }

  .testimonial_section {
    margin: auto;
    margin-top: 50px;
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s;
}
